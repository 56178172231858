.card-result {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    background-color: var(--color-tertiary-brand);
    padding: 32px;

    @media (--viewport-sm-max) {
        padding: 32px 16px;
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tooltip {
            margin-bottom: -60px;
        }

        img {
            margin-bottom: 16px;
        }

        .label {
            margin-bottom: 16px;
        }

        .bodycopy-s {
            margin-top: 16px;
            text-align: center;
        }

    }

    &__form-radio-group {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        .form-radio-group {
            margin-bottom: 0;

            .field-top {
                display: none;
            }

            ul {
                .radio-group__item {
                    margin-right: 16px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &__square {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 0 0;
        border-top: solid 1px var(--color-border-accent-bold);
        border-bottom: solid 1px var(--color-border-accent-bold);
        padding: 16px 0 10px;

        .title-m {
            color: var(--new-color-text);
        }
    }

    &__item {
        margin-top: 32px;
        text-align: center;

        .title-s {
            color: var(--color-secondary-brand);
        }

        .title-xl {
            color: var(--color-text-primary);
        }

        .title-with-tooltip {
            display: block;
            text-align: center;
            margin: 0 10% 16px;

            .title-s {
                display: inline;
            }

            .tooltip {
                top: 22px;
                right: 0;
                left: 8px;
                display: inline;
            }
        }
    }

    &__item-number {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        li {
            padding: 0 3px;
            line-height: normal;

            .bodycopy-s {
                padding-bottom: 11px;
            }
        }
    }

    &__item-clarification {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            padding: 0 3px;
            line-height: normal;
        }
    }

    &__taetin {
        display: flex;
        flex-direction: column;
        border-bottom: solid 1px var(--color-secondary-brand);
        padding: 40px 0 0;
        margin-bottom: 20px;

        li.tin-tae-link {
            margin-bottom: 32px;
            display: flex;
            justify-content: center;

            .icon {
                fill: var(--new-color-text);
                top: 3px;
                right: -5px;
            }
        }

        li.tae {

            .bodycopy-m,
            .title-xl {
                color: var(--color-text);
                line-height: 34px;
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            li {
                margin-bottom: 20px;
                line-height: normal;

                .tooltip {
                    margin-left: 0;
                    top: -18px;
                }

                .tooltip#tooltip-tae {
                    top: -28px;
                }
            }
        }
    }

    &__additional {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        li.tit {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            align-items: center;
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            li {
                margin-bottom: 20px;
                line-height: normal;

                &:last-of-type {
                    white-space: nowrap;
                }

                @media (--viewport-xs-max) {
                    &:first-of-type {
                        max-width: 40%;
                    }
                }
            }

            .tooltip {
                left: 0;
                top: -6px;
                margin-right: 20px;
            }
        }
    }

    &.error {
        justify-content: center;
        text-align: center;
        padding: 56px 32px;
        align-items: center;
        background-color: var(--color-background-accent);

        @media (--viewport-ms-max) {
            padding: 40px 60px;
        }

        @media (--viewport-xs-max) {
            padding: 40px;
        }

        .title-m {
            margin-bottom: 32px;
        }

        /*ul{
            li{
                position: relative;
                padding-left: 25px;
                @media (--viewport-ms-max) {
                    text-align: left;
                }
                &::before {
                    position: absolute;
                    top: 10px;
                    left: 0;
                    display: block;
                    width: 10px;
                    height: 1px;
                    background-color: var(--color-secondary-brand);
                    content: '';
                }
                &:only-of-type{
                    padding-left: 0;
                    &::before{
                        display: none;
                    }
                }
            }
        }
        .bodycopy-m{
            margin-top: 30px;
        }*/
        .icon {
            width: 40px;
            height: 40px;
            margin-bottom: 32px;
        }
    }

    &.fade-out {
        opacity: .5;
    }

    &__item {
        margin-top: 32px;
        text-align: center;

        .title-s {
            color: var(--color-secondary-brand);
        }

        .title-xl {
            color: var(--color-text-primary);
        }

        .title-with-tooltip {
            display: block;
            text-align: center;
            margin: 0 10% 16px;

            .title-s {
                display: inline;
            }

            .tooltip {
                top: 22px;
                right: 0;
                left: 8px;
                display: inline;
            }
        }
    }

    &__item-number {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        li {
            padding: 0 3px;
            line-height: normal;

            .bodycopy-xs {
                padding-bottom: 13px;
            }

            .title-m {
                padding-bottom: 9px;
                color: var(--color-text-primary);
            }

            .bodycopy-l {
                padding-bottom: 9px;
            }

            @media (--viewport-md-max) {
                .bodycopy-xs {
                    padding-bottom: 10px;
                }

                .bodycopy-l {
                    padding-bottom: 6px;
                }

                .title-m {
                    padding-bottom: 4px;
                }
            }

            @media (--viewport-sm-max) {
                .bodycopy-xs {
                    padding-bottom: 7px;
                }

                .bodycopy-l {
                    padding-bottom: 3px;
                }

                .title-m {
                    padding-bottom: 3px;
                }
            }

            @media (--viewport-ms-max) {
                .bodycopy-xs {
                    padding-bottom: 11px;
                }

                .bodycopy-l {
                    padding-bottom: 6px;
                }

                .title-m {
                    padding-bottom: 6px;
                }
            }

            @media (--viewport-xs-max) {
                .bodycopy-xs {
                    padding-bottom: 8px;
                }

                .bodycopy-l {
                    padding-bottom: 3px;
                }

                .title-m {
                    padding-bottom: 4px;
                }
            }
        }
    }

    &__item-clarification {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            padding: 0 3px;
            line-height: normal;

            .title-s {
                padding-bottom: -3px;
                color: var(--color-text-primary);
            }

            .bodycopy-xs {
                margin-bottom: -3px;
            }
        }
    }

    &__taetin {
        display: flex;
        flex-direction: column;
        border-bottom: solid 1px var(--color-secondary-brand);
        padding: 40px 0 0;
        margin-bottom: 20px;

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            li {
                margin-bottom: 20px;
                line-height: normal;

                .tooltip {
                    margin-left: 0;
                    top: -18px;
                }

                .tooltip#tooltip-tae {
                    top: -28px;
                }
            }
        }
    }

    &__additional {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        li.tit {
            .bodycopy-m {
                color: var(--color-text-accent);
            }

            .link_inline {
                color: var(--color-text-link-secondary);
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            li {
                margin-bottom: 20px;
                line-height: normal;

                &:last-of-type {
                    white-space: nowrap;
                }

                @media (--viewport-xs-max) {
                    &:first-of-type {
                        max-width: 40%;
                    }
                }
            }

            .tooltip {
                left: 0;
                top: -6px;
                margin-right: 20px;
            }
        }
    }

    &__additional2 {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        border-top: solid 1px var(--color-secondary-brand);
        padding: 20px 0 0;

        li {
            &:first-of-type {
                margin-bottom: 20px;

                .label-m {
                    color: var(--color-text-subtle);
                }
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            li {
                margin-bottom: 20px;
                line-height: normal;

                &:last-of-type {
                    white-space: nowrap;
                }

                &:first-of-type {
                    margin-bottom: 20px;

                    .label-m {
                        color: initial;
                    }
                }

                @media (--viewport-xs-max) {
                    &:first-of-type {
                        max-width: 40%;
                    }
                }
            }

            .tooltip {
                left: 0;
                top: -6px;
                margin-right: 20px;
            }
        }

    }

    &.fade-out {
        opacity: .5;
    }

    &__nav {
        display: flex;

        @media (--viewport-xs-max) {
            justify-content: space-between;
            padding: 0 20px;
        }

        &-back,
        &-forward {
            margin-top: 30px;
        }

        &-back {
            @media (--viewport-ms-min) {
                margin-right: 30px;
            }
        }
    }

    .wrap-btn{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        .icon{
            fill:var(--color-icon);
        }
    }

    /* Swiper Variation*/
    &-swiper {
        position: sticky;
        top: 0;
    }

    &-swiper.scroll {
        position: fixed;
        width: 100%;
        bottom: -64px;
        left: 20px;
        top: initial;
        z-index: 12;
    }

    &.swiper-slide {
        width: 100%;
        position: relative;
        padding: 32px;

        @media (--viewport-sm-max) {
            padding: 32px 16px;
        }

        .card-result__head {
            background-color: var(--color-background-accent-bold);
            margin: -32px -32px 32px;
            padding: 32px;

            @media (--viewport-sm-max) {
                margin: -32px -16px 32px;
            }

            .title-l {
                color: var(--color-text-inverse);
            }

            .label {
                color: var(--color-text-inverse);
                margin-bottom: 0;
            }

            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(309deg) brightness(107%) contrast(99%);
            }

            &+.bodycopy-s {
                text-align: center;
                min-height: 46px;
            }
        }

        ul.items {
            margin: 16px 0;
            display: flex;
            height: 224px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (--viewport-ms-max) {
                height: 168px;
            }

            li {
                margin-top: 16px;
                width: 100%;

                &:last-child {
                    margin-bottom: 16px;
                }

                .title-with-tooltip {
                    margin-bottom: 4px;
                }

                li {
                    margin-top: 0;
                    width: auto;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .wrap-img {
            height: 37px;
            display: flex;
            align-items: center;
        }

        .card-result__title .bodycopy-s {
            height: 40px;
        }

        .card-result__taetin {
            height: 204px;
            padding-top: 0;
            margin-top: 32px;
            margin-bottom: 32px;
            display: flex;
            position: relative;

            li {
                ul {
                    width: 100%;

                    li {
                        display: flex;
                        margin-bottom: 16px;
                    }
                }

                &.tin-tae-link {
                    position: absolute;
                    bottom: 16px;
                    flex: none;
                    width: 100%;
                    margin-bottom: 18px;
                }

                .tooltip {
                    margin-left: -25px;
                    top: auto;
                }

                .tooltip#tooltip-tae {
                    top: 6px;
                }
            }
        }

        .card-result__additional {

            li.tit {
                margin-bottom: 16px;
            }

            ul {

                li {
                    margin-bottom: 16px;
                }
            }
        }

        .btn {
            float: left;
            margin-top: 40px;
            align-self: start;
        }
    }


    &.swiper-slide.scroll {
        ul.items {
            height: 72px;
        }

        @media (--viewport-ms-max) {
            padding: 24px 20px;
        }

        .card-result__head {
            background-color: unset;
            margin: 0;
            padding: 0 20px 16px;

            .title-l {
                color: var(--color-text-accent);
            }

            .icon {
                fill: var(--color-icon-accent);
                margin-bottom: 16px;
            }

            &+.bodycopy-s {
                text-align: center;
                min-height: 46px;
            }
        }

        .card-result__item-number-scroll {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            padding: 0 32px 8px;
            @media (--viewport-xxs-max) {
                padding: 0 0 8px; 
            }

            &:last-of-type {
                padding-bottom: 0;
            }

            li {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                margin: 0;

                p.title-m {
                    /*line-height: 20px;*/
                    color: var(--color-text);
                    margin-right: 8px;
                }
            }
        }

        .card-result__taetin {
            width: 100%;
            height: auto;
            flex-direction: row;
            flex-wrap: wrap;
            border-bottom: 0;
            margin: 16px 0;

            li {
                width: 50%;

                ul {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0 4px;
                    margin-bottom: 16px;

                    li {
                        width: auto;
                        margin-bottom: 0;
                        padding: 0 2px;
                    }
                }

                &.tae{
                    ul{
                        justify-content: flex-end;
                        border-right: solid 1px var(--color-text);
                    }
                }

                &.tin-tae-link {
                    position: relative;
                    bottom: auto;
                    flex: none;
                    width: 100%;
                    margin-bottom: 0;

                    .link {
                        color: var(--color-text-link-secondary);

                        span {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .wrap-btn {
            justify-content: center;
            padding-top: 8px;
            padding-bottom: 32px;
            flex-direction: row;
            align-items: unset;
            gap: unset;

            .btn {
                margin-top: 0
            }

            .icon{
                fill:var(--color-icon);
            }
        }

        .wrap-link-detalle {
            border-top: solid 1px var(--color-secondary-brand);
            display: flex;
            justify-content: center;
            padding-top: 16px;
        }
    }

    @media (--viewport-ms-max) {
        .swiper-wrapper {
            padding-left: 10px;
        }
    }

    .swiper-container {
        padding-bottom: 50px;

        @media (--viewport-ms-max) {
            padding-bottom: 40px;
        }
    }

    .swiper-slide {
        /*background-color:var(--color-accent-light);*/
        height: auto;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    &__nav {
        display: flex;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        padding: 24px 20px 0;
        justify-content: center;
        align-items: center;

        .swiper-pagination {
            margin: 0 40px;
        }

        .swiper-pagination,
        .swiper-button-prev,
        .swiper-button-next {
            position: absolute;
            top: 60px;

            &::after {
                display: none;
            }

            .icon {
                fill: var(--color-icon-inverse)
            }

            &.scroll {
                .icon {
                    fill: var(--color-icon-accent)
                }
            }
        }

        .swiper-button-prev {
            margin-top: 0;
            left: 32px
        }

        .swiper-button-next {
            margin-top: 0;
            right: 32px
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin-left: 5px;
            margin-right: 5px;
        }

        .swiper-pagination-bullet-active {
            background: var(--color-accent);
        }

        @media (--viewport-ms-max) {
            display: flex;
        }

        &-tabs{
            .menu-tab{margin:40px 0;
                &-scroller__scroll-content{
                    width:100%;
                    .menu-tab-item{
                        width: 33.3%;
                    }
                }
            }
            .title-m{text-align: center;display: block;}
        }
    }
}