.post-headline {
    &__head {
        margin-bottom: 15px;
        @media (--viewport-md-max) {
            margin-bottom: 15px;
        }
        @media (--viewport-ms-max) {
            margin-bottom: 20px;
        }

        &.sticky-clone {
            flex-direction: column;
            min-height: 60px;

            .post-headline {
                &__head {
                    &-wrapper {
                        justify-content: space-between;
                        position: relative;
                        display: inline-flex;
                        align-items: center;
                        width: 100%;
                        max-width: var(--site-width);
                        min-height: 60px;
                        padding-top: 18px;
                        padding-right: calc(var(--gutter-width) - 1px);
                        padding-bottom: 16px;
                        padding-left: var(--gutter-width);

                        @media (--viewport-ms-min) {
                            padding-right: calc(
                                var(--gutter-width-ms-min) + 1px
                            );
                            padding-left: calc(
                                var(--gutter-width-ms-min) + 1px
                            );
                        }

                        @media (--viewport-sm-min) {
                            padding-top: 35px;
                            padding-right: calc(
                                var(--gutter-width-ms-min) - 1px
                            );
                            padding-bottom: 29px;
                            padding-left: calc(
                                var(--gutter-width-ms-min) - 1px
                            );
                        }

                        @media (--viewport-md-min) {
                            padding-right: calc(
                                var(--gutter-width-ms-min) + 2px
                            );
                            padding-left: var(--gutter-width-ms-min);
                        }

                        @media (--viewport-lg-min) {
                            padding-right: var(--gutter-width-lg-min);
                            padding-left: var(--gutter-width-lg-min);
                        }

                        &.active {
                            .post-headline {
                                &__title,
                                &__category {
                                    opacity: 0;
                                    position: absolute;
                                    left: -9999px;
                                }
                                &__socials {
                                    display: block;
                                    width: 100%;
                                    max-width: inherit !important;
                                    flex-basis: auto !important;
                                    padding-left: 0 !important;

                                    .post-headline {
                                        &__socials {
                                            &-trigger {
                                                &-open {
                                                    opacity: 0;
                                                    right: -9999px;
                                                }

                                                &-close {
                                                    opacity: 1;
                                                    right: 0;

                                                    @media (--viewport-ms-max) {
                                                        right: 5%;
                                                        top: 35px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .socials__list {
                                        opacity: 1;
                                        left: 0;
                                    }
                                }
                                &__socials-trigger{
                                    @media (--viewport-ms-max) {
                                        position: initial;
                                    }
                                }
                            }
                        }
                        /*.post-headline__subtitle {
                            display:none;
                        }
                        .post-headline__category {
                            margin-bottom: 0;
                            margin-top: 0;
                            order:2;
                        }
                        .post-headline__title {
                            order:1;
                        }*/
                        .post-headline__socials {
                            order:2;
                        }
                        .wrap{
                            display: flex;
                            order: 1;
                            flex-direction: column;
                            flex-basis: 60%;
                            max-width: 60%;
                            padding-left: 0;
                            text-align: left;

                            @media (--viewport-sm-max) {
                                flex-basis: 50%;
                                max-width: 50%;
                            }

                            @media (--viewport-ms-max) {
                                flex-basis: 70%;
                                max-width: 70%;
                            }

                            @media (--viewport-xs-max) {
                                flex-basis: 50%;
                                max-width: 50%;
                            }
                        }
                    }
                }

                &__title,
                &__category,
                &__socials {
                    padding-right: var(--half-gutter-width);
                    padding-left: var(--half-gutter-width);

                    @media (--viewport-ms-min) {
                        padding-right: var(--half-gutter-width-ms-min, 0.5rem);
                        padding-left: var(--half-gutter-width-ms-min, 0.5rem);
                    }

                    @media (--viewport-lg-min) {
                        padding-right: var(--half-gutter-width-lg-min, 0.5rem);
                        padding-left: var(--half-gutter-width-lg-min, 0.5rem);
                    }
                }

                &__title {
                    

                    .bodycopy-l {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-family: 'Jornada Sans Book';
                        font-size: 16px;
                        font-weight: normal;
                        line-height: 26px;
                        letter-spacing: 0;
                        color: var(--color-accent-dark);

                        @media (--viewport-sm-min) {
                            font-family: 'Jornada News Book';
                            font-size: 18px;
                            line-height: 28px;
                        }

                        @media (--viewport-md-min) {
                            font-size: 26px;
                            line-height: 36px;
                        }

                        @media (--viewport-lg-min) {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                }

                &__category {
                    margin-bottom: 4px;
                    margin-top: 0;

                    @media (--viewport-xs-only) {
                        /*display: none;*/
                    }

                    &-link {
                        display: flex;

                        .text-labels-s {
                            display: inline-block;
                        }
                    }
                }

                &__socials {
                    display: flex;

                    .socials {
                        &__list {
                            @media (--viewport-sm-min) {
                                opacity: 1;
                                position: static;
                                top: 0;
                                left: 0;
                                transform: none;
                            }
                        }

                        &__item {
                            margin: 5px 44px 5px 0;
                                .icon{fill: var(--color-primary-brand);}

                            @media (--viewport-sm-min) {
                                margin-right: 44px;
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                            
                            @media (--viewport-xs-max) {
                                    margin: 5px 25px 5px 0;
                            }
                        }
                    }
                }

                &__button {
                    display: flex;
                    order: 3;
                }

                &__progress {
                    display: block;
                }
            }
        }
    }

    &__title {
        transition: opacity 0.3s;
        .title-xl + .bodycopy-l {
            margin-top: var(--gutter-ms-vertical);
            margin-bottom: var(--gutter-ms-vertical);
            color:var(--color-secondary);
        }

    }

    &__subtitle {
        ul {
            > li {
                position: relative;
                padding-left: 20px;
                margin-bottom: 10px;

                @media (--viewport-ms-min) {
                    padding-left: 30px;
                }

                &::before {
                    position: absolute;
                    top: 11px;
                    left: 0;
                    display: block;
                    width: 15px;
                    height: 1px;
                    background-color: var(--color-accent);
                    content: '';
                }

                &:last-child {
                    margin-bottom: 0;
                }

                > ul {
                    margin-top: 20px;
                    margin-bottom: 0;

                    > li {
                        position: relative;
                        padding-left: 20px;
                        margin-bottom: 10px;

                        &::before {
                            position: absolute;
                            top: 10px;
                            left: 0;
                            display: block;
                            width: 10px;
                            height: 1px;
                            background-color: var(--color-accent);
                            content: '';
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        ol {
            counter-reset: custom-counter;

            li {
                position: relative;
                padding-left: 21px;
                margin-bottom: 10px;
                counter-increment: custom-counter;

                &::before {
                    position: absolute;
                    top: 7px;
                    left: 0;
                    font-family: 'Jornada Sans Book';
                    font-size: 10px;
                    font-weight: normal;
                    line-height: 1;
                    letter-spacing: 0;
                    content: counter(custom-counter) ' /';
                }

                @media (--viewport-ms-min) {
                    padding-left: 28px;
                    margin-bottom: 10px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__category {
        margin-bottom: 25px;
        transition: opacity 0.3s;

        @media (--viewport-ms-max) {
            margin-top: 20px;
            margin-bottom: 25px;
        }

        &-link {
            display: block;

            &:hover,
            &:focus {
                .text-label-s {
                    color: var(--color-accent-dark-tertiary);
                }
            }

            .text-label-s {
                color: var(--color-accent);
                transition: color 0.3s;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    
    &__botton {
        float:right;
    }

    &__socials {
        display: none;
        justify-content: flex-end;
        align-items: center;
        flex-basis: 25%;
        max-width: 25%;
        padding-right: 0;

        @media (--viewport-sm-max) {
            flex-basis: 30%;
            max-width: 30%;
        }
        @media (--viewport-ms-max) {
            flex-basis: 25%;
            max-width: 25%;
        }
        @media (--viewport-xs-max) {
            flex-basis: 10%;
            max-width: 10%;
        }

        &-trigger {
            position: relative;
            display: flex;
            margin-left: auto;
            margin-right: 40px;
            width: 15px;
            height: 15px;

            @media (--viewport-sm-min) {
                display: none;
            }



            @media (--viewport-xs-max) {
                margin-right: 10px;
            }

            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 40px;
                height: 40px;
                content: '';
            }

            &:hover,
            &:focus {
                .icon {
                    fill: var(--color-accent);
                }
            }

            &-open,
            &-close {
                position: absolute;
                top: 0;
                display: flex;
                transition: opacity 0.3s;
            }

            &-open {
                opacity: 1;
                right: 0;
            }

            &-close {
                opacity: 0;
                right: -9999px;
            }

            .icon {
                width: 15px;
                height: 15px;
                fill: var(--color-primary-brand);
            }
        }

        .socials {
            &__list {
                opacity: 0;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: -9999px;
                transition: opacity 0.3s;

                @media (--viewport-ms-max) {
                    width: 100%;
                    margin: 0;
                    padding: 27px 5%;
                    background-color: var(--color-white);
                    .post-headline__socials-trigger{
                        position: initial;
                    }
                }

                @media (--viewport-md-min) {
                    min-width: 152px;
                }

                .icon {
                    width: 20px;
                    height: 20px;

                    @media (--viewport-sm-min) {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    &__button {
        display: none;
    }

    &__progress {
        position: absolute;
        bottom: 0;
        left: 0;
        display: none;
        width: 100%;
    }

    &__info { margin-top: 30px;
        .row{
            .wrap{
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                @media (--viewport-ms-max) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
        &-main {
            display: flex; column-gap: 15px; row-gap: 15px;white-space: nowrap; flex-wrap: wrap;
            @media (--viewport-ms-only) {
                display: flex;
            }
        }
        &-button {
            @media (--viewport-ms-max) {
                margin-top: 25px;
            }
        }
    }

    &__date,
    &__author ,
    &__time {
        display: flex;

        .icon {
            flex-shrink: 0;
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
    }

    /*
    &__author ,
    &__time {
        @media (--viewport-xs-max) {
            margin-top: 10px;
        }

        @media (--viewport-ms-only) {
            margin-left: 40px;
        }

        @media (--viewport-sm-min) {
            margin-top: 20px;
        }
    }

    &__rating-wrapper {
        @media (--viewport-xs-max) {
            margin-top: 10px;
        }

        @media (--viewport-ms-only) {
            margin-left: auto;
        }

        @media (--viewport-sm-min) {
            margin-top: 20px;
        }
    }
    */

    &__rating {
        display: flex;

        /*@media (--viewport-ms-only) {
            margin-left: 40px;
        }*/
    }
}
