.tile-distributor {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__pic {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 265px;
        background: center / cover no-repeat;

        @media (--viewport-ms-min) {
            height: 360px;
        }

        @media (--viewport-sm-min) {
            height: 320px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        /*z-index: 1; Se superpone a los botones del menú mobile*/
        z-index: 0;
        flex: 1;
        padding: 40px 20px;
        margin-top: -40px;
        margin-right: 20px;
        text-align: center;
        background-color: var(--color-accent);
        min-height: 200px;

        @media (--viewport-ms-min) {
            padding: 40px 80px;
            margin-top: -165px;
            margin-right: 93px;
        }

        @media (--viewport-sm-min) {
            padding: 40px 30px;
            margin-top: -160px;
            margin-right: 105px;
        }
        &.accentlight{
            background-color: var(--color-accent-light);
            .tile-distributor__label{
                .text-label-m_color_light{color:var(--color-accent-dark);}  
            }
            .tile-distributor__bodycopy{
                .bodycopy-m.bodycopy_color_light{color:var(--color-accent-dark);}  
            }
            .tile-distributor__title{
                .title-m_color_light{color:var(--color-accent);}
            }
            .tile-distributor__link{
                .link_color_light{color:var(--color-accent-dark);
                    span:after {
                        background-color: var(--color-accent-dark);
                    }
                } 
            }
        }
    }

    &__label {
        margin-bottom: 10px;
    }

    &__bodycopy {
        margin-top: 20px;
    }

    &__link {
        margin-top: 20px;

        @media (--viewport-ms-min) {
            margin-top: 30px;
        }
    }
}
